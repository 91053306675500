<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />

    <v-layout wrap justify-center>
      <v-flex xs12 sm10 px-2 align-self-center>
        <v-layout wrap justify-center>
          <v-flex xs12 xl10>
            <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
              <v-layout wrap justify-center>
                <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
                <v-flex text-right>
                  <v-btn small :ripple="false" text @click="showSnackBar = false">
                    <v-icon style="color: white">mdi-close</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-snackbar>

            <v-layout wrap>
              <v-flex xs12>
                <!-- <v-img
          :src="require('./../../assets/Images/bg.jpg')"
          class="align-center"
          height="100vh"
        > -->
                <v-layout wrap justify-center pa-3 pa-sm-5 pa-lg-0>
                  <v-flex xs12 sm6 pa-lg-16 align-self-start>
                    <v-card tile elevation="4" class="rounded-xl">
                      <v-layout wrap>
                        <v-flex xs12 text-left pb-8>
                          <v-img cover height="170px" :src="require('./../../assets/Images/JobsHeader.jpg')
                            " class="align-center">
                            <v-layout wrap justify-center>
                              <v-flex xs12 align-self-center>
                                <v-img contain height="100px" :src="require('./../../assets/Images/footerLogo1.png')
                                  " class="align-center">
                                </v-img>
                              </v-flex>
                            </v-layout>
                          </v-img>
                        </v-flex>

                        <v-flex xs12 align-self-center py-6>
                          <v-layout wrap>
                            <v-flex xs12 text-center px-6>
                              <span class="popbold">Welcome to WTI </span>
                              <br />
                              <br />

                              <!-- <v-btn
                        :color="appColor"
                        dark
                        rounded
                        style="
                          padding-left: 30px;
                          padding-right: 30px;
                          text-transform: none;
                        "
                        @click="goTo"
                        small
                        >Login to shop with us!</v-btn
                      > -->

                              <v-card tile>
                                <v-layout wrap justify-center py-2>
                                  <v-flex xs12 px-8>
                                    <v-layout wrap justify-center>
                                      <v-flex xs12>
                                        <span style="
                                            font-family: sofiaPro;
                                            font-size: 18px;
                                            color: black;
                                          ">
                                          <!-- Register -->
                                          Enter Your Mobile Number To Track Your Order
                                        </span>
                                      </v-flex>

                                      <v-flex xs12>
                                        <v-layout wrap justify-center>
                                          <v-flex xs12 align-self-center text-center>
                                            <v-layout wrap>
                                              <v-flex pt-4 xs12 text-left>
                                                <v-layout wrap justify-center>
                                                  <v-flex xs12 pb-2>
                                                    <span style="
                                                        font-family: poppinsregular;
                                                        font-size: 12px;
                                                        color: black;
                                                      ">
                                                      Enter your Mobile Number
                                                    </span>
                                                  </v-flex>
                                                  <v-flex xs12>
                                                    <v-form @submit.prevent="
                                                      validateInput()
                                                      ">
                                                      <v-text-field ref="phone" color="#717171"
                                                        placeholder="Mobile number" outlined dense type="number"
                                                        v-model="phone" :rules="[
                                                          rules.required,
                                                          rules.min,
                                                        ]">
                                                        <template v-slot:prepend-inner>
                                                          <v-layout justify-center>
                                                            <v-flex pt-0 class="
                                                                align-self-center
                                                              ">
                                                              <span style="
                                                                  font-family: poppinsregular;
                                                                  font-size: 15px;
                                                                  color: #000;
                                                                ">+91</span>
                                                            </v-flex>
                                                            <v-flex pt-1 pl-2 xs1>
                                                              <v-divider vertical></v-divider>
                                                            </v-flex>
                                                          </v-layout>
                                                        </template>
                                                      </v-text-field>
                                                    </v-form>
                                                  </v-flex>
                                                </v-layout>
                                              </v-flex>
                                              <v-flex xs12 py-6>
                                                <v-btn block :color="appColor" dark :ripple="false" depressed
                                                  @click="validateInput()">
                                                  <span style="
                                                      font-family: poppinssemibold;
                                                      font-size: 12px;
                                                      text-transform: none;
                                                    ">Continue
                                                  </span>
                                                </v-btn>
                                              </v-flex>
                                            </v-layout>
                                          </v-flex>
                                        </v-layout>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-card>
                            </v-flex>
                            <!-- <v-flex xs12 text-center py-3>
                              <span class="popregular">Not a member? </span>
                              <v-btn color="#242B48" dark rounded style="
                                  padding-left: 30px;
                                  padding-right: 30px;
                                  text-transform: none;
                                " @click="goTosignup" small>Signup</v-btn>
                            </v-flex> -->
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["pageData", "backgroundImage", "type"],
  data() {
    return {
      userType: "signin",
      tab: null,
      items: ["Login", "Signup"],
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      formHasErrors: false,
      timeout: 5000,
      msg: null,
      phone: "",
      rules: {
        required: (value) => !!value || "Required",
        min: (value) => value.length == 10 || "Invalid Phone",
      },
      isGuest: null
    };
  },
  created() {
    this.isGuest = localStorage.getItem('GuestID')
  },
  beforeMount() {
    if (this.pageData.phone) {
      this.phone = this.pageData.phone;
    }
  },
  computed: {
    form() {
      return {
        phone: this.phone,
      };
    },
    // isGuest() {
    //   return localStorage.getItem('GuestID')
    // }
  },
  methods: {
    goTosignup() {
      this.$emit("stepper", {
        winStep: 4,
        type: "signup",
      });
    },
    validateInput() {
      this.userType = this.type;
      this.formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) {
          this.formHasErrors = true;
          this.msg = "Please Provide " + f;
          this.showSnackBar = true;
          return;
        }
        if (!this.$refs[f].validate(true)) {
          this.msg = "Please Provide Valid " + f;
          this.showSnackBar = true;
          this.formHasErrors = true;
          return;
        }
      });
      if (this.formHasErrors == false) this.login();
    },
    // login() {
    //   var userData = {};
    //   userData["phone"] = this.phone;
    //   userData["accessType"] = this.userType;
    //   if(localStorage.getItem('GuestID')) {
    //     userData["guestId"] = localStorage.getItem('GuestID')
    //   }
    //   this.appLoading = true;
    //   axios({
    //     method: "get",
    //     url: "user/signup/signin/send/otp",
    //     params: {
    //       phone: this.phone,
    //       accessType: this.userType,
    //       guestId: localStorage.getItem('GuestID')
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status) {
    //         if (response.data.alreadyExists == false) {
    //           this.$emit("stepper", {
    //             phone: this.phone,
    //             winStep: 2,
    //           });
    //         }
    //         if (response.data.alreadyExists == true) {
    //           this.$emit("stepper", {
    //             phone: this.phone,
    //             winStep: 1,
    //           });
    //         }
    //       } else {
    //         // this.$emit("stepper", {
    //         //   phone: this.phone,
    //         //   winStep: 2,
    //         // });
    //         this.showSnackBar = true;
    //         this.msg = response.data.msg;
    //       }
    //     })
    //     .catch((err) => {
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    login() {
      this.appLoading = true;
      var userData = {};
      userData["phone"] = this.phone;
      userData["accessType"] = this.userType;
      if (localStorage.getItem('GuestID')) {
        userData["guestId"] = localStorage.getItem('GuestID')
      }
      axios({
        method: "POST",
        url: "user/signup/signin",
        data: {
          accessType: "signin",
          phone: this.phone,
          otp: 1234,
          role: "customer",
          guestId: localStorage.getItem('GuestID')
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (response.data.role == "customer") {
              // new wti

              this.$store.commit("userType", response.data.role);
              this.$store.commit("userData", response.data.data);
              this.$store.commit("changeCart", response.data.cartcount);

              this.$store.commit("loginUser", response.data.token);
              // new wti

              localStorage.setItem("token", response.data.token);

              if (response.data.profileStatus == "active") {
                this.$store.commit("userData", response.data.data);
                this.$router.push("/");
              }
            } else {
              this.msg2 = "Please try after some time!";
            }
          } else if(response.data.status==false && response.data.msg=='You are not registered!Please sign up'){
            // this.msg2 = response.data.msg;
            this.signin()
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    signin() {
      var userData = {};
      // userData["firstname"] = this.firstname;
      // userData["lastname"] = this.lastname;
      // userData["email"] = this.email;
      userData["accessType"] = 'signup';
      userData["phone"] = this.phone;
      // userData["pincode"] = this.pincode;
      userData["otp"] = 1234;
      userData["role"] ='customer';
      if(localStorage.getItem('GuestID')) {
        userData["guestId"] = localStorage.getItem('GuestID')
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "user/signup/signin",
        data: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            localStorage.removeItem("regType");
          //  this.$store.commit("LOGIN_ALTERNATE",false)
            // this.$store.commit("changeCart", 0);
            // this.$store.commit("changeWishlist", 0);
            this.$store.commit("userData", response.data);
            this.$store.commit("userType", response.data.role);
            // this.$store.commit("registrationStage", response.data.level);
            this.$store.commit("loginUser", response.data.token);
              this.$router.push("/profile");
          } 
          else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
