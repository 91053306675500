<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-app id="inspire">
      <v-layout
        wrap
        justify-center
        py-4
       
      >
        <v-flex xs12 sm8 md7 lg5 xl5 px-2 align-self-center>
          <v-layout wrap justify-center>
            <v-flex xs112 xl8>
              <SnackBar
                v-bind:msg="msg"
                v-bind:showSnackBar="showSnackBar"
                @stepper="showSnackBar = false"
              />
              <v-card tile elevation="4" class="rounded-xl">
                <v-layout wrap justify-center py-8>
                  <v-flex px-8 py-6>
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <span
                          style="
                            font-family: poppinsmedium;
                            font-size: 18px;
                            color: #090909;
                          "
                          >OTP Verification</span
                        >
                      </v-flex>
                      <v-flex xs12 pt-4 style="
                            font-family: sofialight;
                            font-size: 12px;
                            color: #7b7b7b;
                          ">
                       Please enter OTP sent to your Mobile
                      </v-flex>
                      <!-- <v-flex xs4  py-2>
                                <v-progress-linear
                                  height="2"
                                  value="100"
                                  color="grey"
                                ></v-progress-linear>
                              </v-flex> -->
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          
                          
                        </v-layout>
                      </v-flex>
                     
                    </v-layout>

                    
                    <v-layout wrap justify-center>
                      
                      <!-- <v-flex py-2 text-right>
                        <span
                          style="
                            font-family: poppinsregular;
                            font-size: 13px;
                            color: #de4d4d;
                            cursor: pointer;
                          "
                          @click="winStepper"
                        >
                          Change
                        </span>
                      </v-flex> -->
                      <v-flex xs12 md6 py-8>
                        <!-- <v-text-field
                          ref="otp"
                          label="Enter your OTP"
                          type="number"
                          color="#828282"
                          outlined
                          dense
                          :rules="[rules.required, rules.otp]"
                          v-model="otp"
                        ></v-text-field> -->
                         <vie-otp-input
                          inputClasses="otp-input"
                          :numInputs="4"
                          separator
                          :shouldAutoFocus="true"
                          @on-complete="validateInput"
                          
                        />
                      </v-flex>
                      <v-flex xs12 md7 pt-4 text-right>
                        <v-btn
                          block
                          dark
                          :color="appColor"
                          depressed
                          class="text-capitalize"
                          @click="validateInput"
                          :ripple="false"
                        >
                          <span
                            style="
                              font-family: poppinssemibold;
                              font-size: 14px;
                            "
                          >
                            Continue 
                          </span>
                        </v-btn>
                      </v-flex>

                      <v-flex xs12 text-center pt-2 pl-2>
                        <span
                          v-if="!otpexpired"
                          style="
                            font-family: sofialight;
                            font-size: 13px;
                            color: #515151;
                          "
                        >
                          Waiting for OTP... {{ timeLeft }} sec
                        </span>
                        <span
                          v-if="otpexpired"
                          style="
                            font-family: sofialight;
                            font-size: 13px;
                            color: #515151;
                            cursor: pointer;
                          "
                          @click="sentOTP"
                        >
                          Resend OTP
                        </span>
                      </v-flex>
                    </v-layout>
                    <!-- <v-layout wrap justify-end>
                      <v-flex xs12 md4 pt-4 text-right>
                        <v-btn
                          block
                          dark
                          tile
                          color="#3EA56E"
                          depressed
                          class="text-capitalize"
                          @click="validateInput"
                          :ripple="false"
                        >
                          <span
                            style="
                              font-family: poppinssemibold;
                              font-size: 14px;
                            "
                          >
                            Continue 
                          </span>
                        </v-btn>
                      </v-flex>
                    </v-layout> -->
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-app>
  </div>
</template>
<script>
import VieOtpInput from "@bachdgvn/vue-otp-input";
import axios from "axios";
export default {
   components: {
    VieOtpInput,
  },
  props: ["pageData", "lastPage", "backgroundImage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,

      firstname: null,
      lastname: null,
      email: null,
      password: "",
      phone: null,
      confirmPassword: "",
      pincode: "",
      otp: "",
      timeLeft: "00:00",
      intervalTimer: null,
      otpexpired: false,
      user: {
        customer: true,
        seller: false,
      },
      type: "Client",
      valid: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (value) => value.length >= 8 || "Min 8 characters",
        pincode: (value) => value.length == 6 || "Please provide valid pincode",
        otp: (value) => value.length == 4 || "Please provide valid otp",
        check: (confirmation) =>
          confirmation === this.password || "Passwords do not match",
      },

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
    };
  },

  beforeMount() {
      if ( this.$store.state.loginAlternate)
      localStorage.setItem("regType","Client");
    var user = localStorage.getItem("regType");
  
    if (user == "Seller") {
      this.user.customer = false;
      this.user.seller = true;
      this.type = "Seller";
    }
    //  && localStorage.getItem("regType")=='Client'
  
    this.setTime(180);
    this.phone = this.pageData.phone;
  },
//   watch:{
//     $route (to, from){
//         this.show = false;
//     }
// } ,
  methods: {
    userChange(item) {
      if (item == "customer") {
        this.user.customer = true;
        this.user.seller = false;
        this.type = "Client";
      } else {
        this.user.customer = false;
        this.user.seller = true;
        this.type = "Seller";
      }
    },
    winStepper() {
      this.$emit("stepper", {
        phone: this.pageData.phone,
        response: null,
        winStep: 0,
      });
    },
    goBack() {},
    sentOTP() {
      var userData = {};
      userData["phone"] = this.pageData.phone;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/getsms",
        data: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.setTime(180);
            this.otpexpired = false;
            this.msg = "OTP Sent Successfully";
            this.showSnackBar = true;
          } else {
            this.msg = "Something Went Wrong";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    validateInput(value) {
      
      // if (!this.otp || this.otp == "") {
      //   this.msg = "Please provide the OTP received in your mobile number";
      //   this.showSnackBar = true;
      //   return;
      // }
      this.otp =value;
      // if (this.password != this.confirmPassword) {
      //   this.msg = "Passwords doesn't match";
      //   this.showSnackBar = true;
      //   return;
      // }
      this.signUp();
    },
    signUp() {
      var userData = {};
      // userData["firstname"] = this.firstname;
      // userData["lastname"] = this.lastname;
      // userData["email"] = this.email;
      userData["accessType"] = 'signup';
      userData["phone"] = this.phone;
      // userData["pincode"] = this.pincode;
      userData["otp"] = this.otp;
      userData["role"] ='customer';
      if(localStorage.getItem('GuestID')) {
        userData["guestId"] = localStorage.getItem('GuestID')
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "user/signup/signin",
        data: userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            localStorage.removeItem("regType");
          //  this.$store.commit("LOGIN_ALTERNATE",false)
            // this.$store.commit("changeCart", 0);
            // this.$store.commit("changeWishlist", 0);
            this.$store.commit("userData", response.data);
            this.$store.commit("userType", response.data.role);
            // this.$store.commit("registrationStage", response.data.level);
            this.$store.commit("loginUser", response.data.token);
              this.$router.push("/profile");
          } 
          else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    setTime(seconds) {
      clearInterval(this.intervalTimer);
      this.timer(seconds);
    },
    timer(seconds) {
      const now = Date.now();
      const end = now + seconds * 1000;
      this.displayTimeLeft(seconds);
      this.countdown(end);
    },
    countdown(end) {
      this.intervalTimer = setInterval(() => {
        const secondsLeft = Math.round((end - Date.now()) / 1000);
        if (secondsLeft == 0) this.otpexpired = true;
        if (secondsLeft < 0) {
          clearInterval(this.intervalTimer);
          return;
        }
        this.displayTimeLeft(secondsLeft);
      }, 1000);
    },
    displayTimeLeft(secondsLeft) {
      const minutes = Math.floor((secondsLeft % 3600) / 60);
      const seconds = secondsLeft % 60;
      var seconds1 = seconds;
      if (seconds < 10) seconds1 = "0" + seconds;
      this.timeLeft = "0" + minutes + " : " + seconds1;
      // this.timeLeft = seconds1;
    },
  },
};
</script>